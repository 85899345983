import React, { FC, useEffect, useState } from "react";
import {
  Container,
  CssBaseline,
  Avatar,
  Button,
  Grid,
  Link,
  Typography,
  ThemeProvider,
  Box,
  createTheme,
  Snackbar,
  Alert,
  FilledInput,
  IconButton,
  InputAdornment,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useAppDispatch } from "../app/hooks";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { useLoginMutation } from "../app/services/auth";
import { setCredentials } from "../app/features/authSlice";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

const theme = createTheme();

const Login: FC = () => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isLoginSuccessfull, setIsLoginSuccessfull] = useState(false);
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [formState, setFormState] = useState<LoginRequest>({
    username: "",
    password: "",
  });

  const [doLogin, { error, isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    formState.username.trim() && formState.password.trim()
      ? setIsLoginButtonDisabled(false)
      : setIsLoginButtonDisabled(true);
  }, [formState]);

  async function handleSubmit(e: any) {
    e.preventDefault();

    const loginReqest: LoginRequest = {
      username: formState.username,
      password: formState.password,
      productId: "1A78FF3B-60FE-4E4B-5D53-08D976A4EDC9",
      clientId: "TargetCRM",
      clientSecret: "2'XC1&f#Dd8Rt%.)F{ss6?_$`S&cEj",
    };

    await doLogin(loginReqest)
      .unwrap()
      .then((payload) => {
        dispatch(
          setCredentials({
            username: formState.username,
            password: formState.password,
            token: {
              accessToken: payload.access_token,
              expiresIn: payload.expires_in,
              refreshToken: payload.refresh_token,
              tokenType: payload.token_type,
              scope: payload.scope,
            },
          })
        );
        setSnackbarMessage("Logged in successfully");
        const token_decoded = jwtDecode<any>(payload.access_token);
        localStorage.setItem(
          "idms_email",
          token_decoded?.name ?? token_decoded.email
        );
        setIsLoginSuccessfull(true);
        setIsSnackbarOpen(true);
        navigate("/dashboard/app");
      })
      .catch((error) => {
        setSnackbarMessage(error?.data?.Errors?.IdentityError[0] ?? "Invalid Username or Password");
        setIsSnackbarOpen(true);
        setIsLoginSuccessfull(false);
      });
  }

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setFormState((prev) => ({ ...prev, [name]: value }));

  const handleClickShowPassword = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const handleMouseDownPassword = (event: any) => event.preventDefault();

  const handleSnackbarClose = () => setIsSnackbarOpen(false);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />

        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity={isLoginSuccessfull ? "success" : "error"}
            onClose={handleSnackbarClose}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Box
          sx={{
            my: 16,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#44444",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            // noValidate
            sx={{ mt: 1 }}
          >
            <FormControl sx={{ my: 1, width: "100%" }} variant="filled">
              <InputLabel htmlFor="username">Username</InputLabel>
              <FilledInput
                name="username"
                type={"text"}
                // value={formState.username}
                required
                autoFocus
                autoComplete="username"
                onChange={handleChange}
                sx={{
                  input: {
                    background: "white",
                  },
                }}
              />
            </FormControl>
            <FormControl sx={{ my: 1, width: "100%" }} variant="filled">
              <InputLabel htmlFor="password">Password</InputLabel>
              <FilledInput
                name="password"
                type={isPasswordVisible ? "text" : "password"}
                // value={formState.password}
                required
                autoComplete="current-password"
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                sx={{
                  "& input": {
                    backgroundColor: "white",
                    "& ~ .MuiInputAdornment-root": {
                      backgroundColor: "white",
                      p: 4,
                      m: -4,
                    },
                  },
                }}
              />
            </FormControl>

            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs>
                <Link
                  href={`${process.env.REACT_APP_IDMS_API_URL}/.well-known/openid-configuration`}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  <Typography variant="body2" color="primary">
                    Discovery Document
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={isLoginButtonDisabled || isLoading}
                >
                  Login
                  {isLoading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: -12,
                        marginLeft: -12,
                        color: "secondary",
                      }}
                      size={24}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;

export enum FilterOperator {
  Contains = "Contains",
  Equals = "Equals",
  NotEquals = "Not Equals",
  StartsWith = "Starts With",
  EndsWith = "Ends With",
  LessThan = "<",
  GreaterThan = ">",
  LessThanOrEqual = "<=",
  GreaterThanOrEqual = ">=",
  IsEmpty = "Is Empty",
  IsNotEmpty = "Is Not Empty",
}

export const FilterColumnObj = {
  string: {
    UserName: "userName",
    ByName: "byName",
    Name: "name",
    ProductName: "productName",
  },
  number: {
    DealerId: "dealerId",
    DmsDealerId: "dmsDealerId",
  },
};

export enum UserFilterColumn {
  UserName = "userName",
  DealerId = "dealerId",
  DmsDealerId = "dmsDealerId",
}

export enum RoleFilterColumn {
  Name = "name",
  DmsDealerId = "dmsDealerId",
}

export enum ProductFilterColumn {
  ProductName = "productName",
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { logout, setToken } from "../features/authSlice";
import { API_REFRESHTOKEN_ENDPOINT } from "../../constants/endpoints";
import { Mutex } from "async-mutex";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_IDMS_API_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const accessToken = (getState() as RootState).auth.token.accessToken;

    if (accessToken && endpoint !== "login") {
      headers.set("Content-Type", "application/json");
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && [401].includes(result.error.status as number)) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        // try to get a new token
        const refreshResult = await baseQuery(
          {
            url: API_REFRESHTOKEN_ENDPOINT,
            method: "POST",
            body: JSON.stringify({
              refreshToken: (api.getState() as RootState).auth.token
                .refreshToken,
              clientId: "TargetCRM",
              clientSecret: "2'XC1&f#Dd8Rt%.)F{ss6?_$`S&cEj",
            }),
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          // store the new token
          const loginResponse = refreshResult.data as LoginResponse;
          api.dispatch(
            setToken({
              accessToken: loginResponse.access_token,
              expiresIn: loginResponse.expires_in,
              refreshToken: loginResponse.refresh_token,
              tokenType: loginResponse.token_type,
              scope: loginResponse.scope,
            })
          );
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
          // move to login page
          window.location.href = "/login";
        }
      } catch (e) {
        console.error(e);
        api.dispatch(logout());
        // move to login page
       window.location.href = "/login";
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const idmsApi = createApi({
  reducerPath: "idmsApi",
  baseQuery: baseQueryWithReauth,
  // global configuration for the api
  // keepUnusedDataFor: 600,
  tagTypes: ["Auth", "User", "UserRole", "RoleUser", "UserProduct"],
  endpoints: () => ({}),
});

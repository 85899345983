import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialPaginationModel } from "../../constants/variables";
import { useAppSelector } from "../hooks";

const userInitialState = {
  paginationModel: initialPaginationModel,
  columnVisisbilityModel: {
    userName: true,
    dealerId: true,
    dmsDealerId: true,
    userId: true,
    email: true,
    emailConfirmed: true,
    phoneNumber: true,
    lastLoginTime: true,
    actions: true,
    fullName: false,
    lockoutEnabled: false,
    lockoutEnd: false,
    createdDateTime: false,
    updateDateTime: false,
    isDmsAdmin: false,
  } as GridColumnVisibilityModel,
};

const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    setUserPaginationModel: (state, action: PayloadAction<PaginationModel>) => {
      state.paginationModel = action.payload;
    },
    setUserColumnVisibilityModel: (
      state,
      action: PayloadAction<GridColumnVisibilityModel>
    ) => {
      state.columnVisisbilityModel = action.payload;
    },
  },
});

export const { setUserPaginationModel, setUserColumnVisibilityModel } =
  userSlice.actions;

export default userSlice.reducer;

export const useUserPaginationModel = () =>
  useAppSelector((state) => state.user.paginationModel);
export const useUserColumnVisibilityModel = () =>
  useAppSelector((state) => state.user.columnVisisbilityModel);

import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { renderCellValue } from "./types/renderCellValue";

export function getUserColumns(
  setActions?: (params: GridRowParams<any>) => JSX.Element[],
  renderCellValues?: renderCellValue[]
): GridColDef[] {
  let userColumns = [
    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
      minWidth: 100,
      filterable: true,
    },
    {
      field: "dealerId",
      headerName: "Dealer Id",
      flex: 1,
      minWidth: 100,
      filterable: true,
    },
    {
      field: "dmsDealerId",
      headerName: "Dms Dealer Id",
      flex: 1,
      minWidth: 100,
      filterable: true,
    },
    {
      field: "userId",
      headerName: "User Id",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "emailConfirmed",
      headerName: "Email Confirmed",
      type: "boolean",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "lockoutEnabled",
      headerName: "Lockout Enabled",
      type: "boolean",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "lockoutEnd",
      headerName: "Lockout End",
      type: "string",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "createdDateTime",
      headerName: "Created Date Time",
      type: "string",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "updateDateTime",
      headerName: "Updated Date Time",
      type: "string",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "lastLoginTime",
      headerName: "Last Login Time",
      type: "string",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      field: "isDmsAdmin",
      headerName: "Is Dms Admin",
      type: "boolean",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
  ] as GridColDef[];

  if (renderCellValues)
    renderCellValues.forEach((renderCellValue) => {
      let userColumn = userColumns.find(
        (userColumn) => userColumn.field === renderCellValue.field
      );
      if (userColumn) userColumn.renderCell = renderCellValue.cellRenderer;
    });

  if (setActions)
    userColumns.push({
      field: "actions",
      headerName: "Action",
      flex: 1,
      minWidth: 150,
      type: "actions",

      getActions: (params: GridRowParams<any>) => setActions(params),
    });

  return userColumns;
}

import { FC, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  useGetCategoriesMutation,
  useGetTaxCodeMutation,
  useUpsertTaxCodeDataMutation,
} from "../../../app/services/salestax";
import { useGetDealerId } from "../../../app/features/salestaxSlice";
import { toast } from "react-toastify";

const TaxCodeMapping: FC = () => {
  const DealerId = useGetDealerId();
  const [formState, setFormState] = useState({
    category: "",
    taxCode: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  /*
   * API calls
   * */

  // Get Categories
  const [
    getCategory,
    {
      data: categoryData,
      error: categoryError,
      isError: categoryIsError,
      isLoading: categoryIsLoading,
      isSuccess: categoryIsSuccess,
    },
  ] = useGetCategoriesMutation();

  const getCategoryList = async () => {
    const requestBody = {
      query: "SalesTax.InventoryCategory",
      queryBody: { DealerId },
    };

    await getCategory(requestBody)
      .then((response: any) => {
        // console.log("Category Response => ", response);
      })
      .catch((error: any) => {
        toast.error("Fetching Category Failed");
      });
  };

  // Get Avalara Tax-Code
  const [
    getTaxCode,
    {
      data: taxCodeData,
      error: taxCodeError,
      isError: taxCodeIsError,
      isLoading: taxCodeIsLoading,
      isSuccess: taxCodeIsSuccess,
    },
  ] = useGetTaxCodeMutation();

  const getTaxCodeList = async () => {
    await getTaxCode({ dealerId: DealerId })
      .then((response: any) => {
        // console.log("Tax Code Response => ", response);
      })
      .catch((error: any) => {
        toast.error("Fetching Tax Code Failed");
      });
  };

  // Update/Get Tax Code Mapping Data
  const [
    upsertTaxCodeData,
    {
      data: upsertTaxCodeRes,
      error: upsertTaxCodeError,
      isError: upsertTaxCodeIsError,
      isLoading: upsertTaxCodeIsLoading,
      isSuccess: upsertTaxCodeIsSuccess,
    },
  ] = useUpsertTaxCodeDataMutation();

  const getTaxCodeSettings = async () => {
    const requestBody = {
      query: "SalesTax.TaxCodeSetting",
      queryBody: { DealerId },
    };

    try {
      await upsertTaxCodeData(requestBody);

      if (upsertTaxCodeIsSuccess) {
        toast.success("Tax code data fetched successfully");
      }
    } catch (error) {
      if (upsertTaxCodeIsError) {
        toast.error("Tax code data fetching failed");
      } else {
        // Handle any other unexpected errors
        console.error("Unexpected error:", error);
        toast.error("An unexpected error occurred");
      }
    }
  };

  /*
   * Effects
   * */

  // Get Categories
  useEffect(() => {
    getCategoryList();
  }, []);

  // Get Avalara Tax-Code
  useEffect(() => {
    getTaxCodeList();
  }, []);

  // Get Tax Code Mapping Data
  useEffect(() => {
    getTaxCodeSettings();
  }, []);
  /*
   * Handlers
   * */

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const resetState = () => {
    setFormState((prevSettings) => ({
      ...prevSettings,
      category: "",
      taxCode: "",
    }));
  };

  //* Check if any of the required fields for save configs API are empty
  const isFormComplete = () => {
    return formState.category !== "" && formState.taxCode !== "";
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const requestBody = {
      query: "SalesTax.TaxCodeSetting",
      queryBody: {
        DealerId,
        CategoryId: formState.category,
        AvaTaxCode: formState.taxCode,
      },
    };

    try {
      const response = await upsertTaxCodeData(requestBody);

      if (upsertTaxCodeIsSuccess) {
        toast.success("Tax code data updated successfully");
        resetState();

        // fetch Tax-Code Settings
        getTaxCodeSettings();
      }
    } catch (error) {
      if (upsertTaxCodeIsError) {
        toast.error("Tax code data updating failed");
        resetState();
      } else {
        // Handle any other unexpected errors
        console.error("Unexpected error:", error);
        toast.error("An unexpected error occurred");
        resetState();
      }
    }
  };

  const categoryDefaultProps = {
    options: categoryData,
    getOptionLabel: (option: any) => option.Description || "",
  };

  const taxCodeDefaultProps = {
    options: taxCodeData?.value,
    getOptionLabel: (option: any) => option?.description || "",
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="left"
      justifyContent="center"
    >
      <Typography
        sx={{
          fontWeight: "bold",
          marginBottom: "25px",
        }}
      >
        Tax Code Mapping
      </Typography>

      <Grid container spacing={2}>
        {/* Left Grid */}
        <Grid item xs={6} p={2}>
          <form>
            <Stack direction="row" alignItems="center" sx={{ marginBottom: 2 }}>
              <Typography variant="body2" sx={{ mr: 2, minWidth: 100 }}>
                Category:
              </Typography>

              <Autocomplete
                {...categoryDefaultProps}
                id="category"
                sx={{ width: "50%" }}
                clearOnEscape
                loading={categoryIsLoading}
                onChange={(_, newValue: any) => {
                  setFormState((prevSettings) => ({
                    ...prevSettings,
                    category: newValue?.CategoryId ?? "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    required
                  />
                )}
              />
            </Stack>

            <Stack direction="row" alignItems="center" sx={{ marginBottom: 2 }}>
              <Typography variant="body2" sx={{ mr: 2, minWidth: 100 }}>
                Tax Code:
              </Typography>

              <Autocomplete
                {...taxCodeDefaultProps}
                id="taxCode"
                sx={{ width: "50%" }}
                clearOnEscape
                loading={taxCodeIsLoading}
                onChange={(_, newValue: any) => {
                  setFormState((prevSettings) => ({
                    ...prevSettings,
                    taxCode: newValue?.description ?? "",
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    required
                  />
                )}
              />
            </Stack>

            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              disabled={!isFormComplete() || upsertTaxCodeIsLoading}
              sx={{
                textTransform: "capitalize",
                marginTop: 2,
                marginBottom: 2,
                width: "150px",
              }}
            >
              Save
              {upsertTaxCodeIsLoading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                    color: "secondary",
                  }}
                  size={24}
                />
              )}
            </Button>
          </form>
        </Grid>

        {/* Right Grid */}
        <Grid item xs={6} p={2}>
          <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
            <Table aria-label="simple table">
              <TableHead sx={{ background: "#e5e5e5" }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Sr. No.</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Category Id</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Ava Tax Code
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {upsertTaxCodeRes?.length > 0 &&
                  upsertTaxCodeRes
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row: any, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>{row.CategoryId}</TableCell>
                        <TableCell>{row.AvaTaxCode}</TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={upsertTaxCodeRes?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TaxCodeMapping;

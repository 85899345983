import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { renderCellValue } from "./types/renderCellValue";

export function getProductColumns(
  setActions?: (params: GridRowParams<any>) => JSX.Element[],
  renderCellValues?: renderCellValue[]
): GridColDef[] {
  let productColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      filterable: false,
      flex: 0.5 
    },
    {
      field: "productName",
      headerName: "Product Name",
      flex: 1,
      filterable: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      filterable: false,
    },
    {
      field: "productType",
      headerName: "Product Type",
      flex: 1,
      filterable: false,
    },
  ];

  if (renderCellValues)
    renderCellValues.forEach((renderCellValue) => {
      let productColumn = productColumns.find(
        (productColumn) => productColumn.field === renderCellValue.field
      );
      if (productColumn)
        productColumn.renderCell = renderCellValue.cellRenderer;
    });

  if (setActions)
    productColumns.push({
      field: "actions",
      headerName: "Action",
      width: 150,
      type: "actions",

      getActions: (params: GridRowParams<any>) => setActions(params),
    });

  return productColumns;
}

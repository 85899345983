import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useNavigate } from "react-router-dom";
import GarageIcon from "@mui/icons-material/Garage";

const Dashboard = () => {
  const navigate = useNavigate();

  const handleProductsClick = () => {
    navigate("/dashboard/products");
  };

  return (
    <Container sx={{ marginTop: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              height: "100%",
              boxShadow: 3,
              ":hover": { boxShadow: 6, backgroundColor: "#f0f0f0" },
            }}
          >
            <CardActionArea onClick={handleProductsClick}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  textAlign: "center",
                }}
              >
                <InventoryIcon sx={{ fontSize: 50, color: "#ff9800", mb: 2 }} />
                <Typography
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#ff9800",
                  }}
                >
                  Products
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    color: "#555",
                  }}
                >
                  Manage product and features
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              height: "100%",
              boxShadow: 3,
              opacity: 0.5, // Adjust opacity to visually indicate disabled state
              pointerEvents: "none", // Disable pointer events
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                textAlign: "center",
              }}
            >
              <GarageIcon sx={{ fontSize: 50, color: "#1976d2", mb: 2 }} />
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#1976d2",
                }}
              >
                Dealer Management (Coming Soon)
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: "#555",
                }}
              >
                
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;

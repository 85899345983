import styled from "@emotion/styled";
import { TableHead } from "@mui/material";

export const drawerWidth = 240;
export const StyledTableHead = styled(TableHead)({
  '& .MuiTableCell-root': {
    backgroundColor: "#eaecee",
  },
});
export const initialPaginationModel: PaginationModel = {
  page: 0,
  pageSize: 25,
};

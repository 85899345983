import { idmsApi } from "./api";
import { API_USERROLE_ENDPOINT } from "../../constants/endpoints";

const userRoleApi = idmsApi.injectEndpoints({
  endpoints: (builder) => ({
    assignUserRole: builder.mutation<any, CreateUserRole>({
      query: (request) => ({
        url: API_USERROLE_ENDPOINT,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["UserRole", "RoleUser"],
    }),

    getUserRoles: builder.query<CreateRoleResponse[], string>({
      query: (userId) => `${API_USERROLE_ENDPOINT}/${userId}/role`,
      providesTags: ["UserRole"],
    }),

    unassignUserRole: builder.mutation<any, UnassignUserRole>({
      query: (request) => ({
        url: API_USERROLE_ENDPOINT,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: ["UserRole", "RoleUser"],
    }),

    getUserRolesByDealerAndProduct: builder.query<any, {  dealerId: number, productId: string }>({
      query: ({ dealerId, productId }) => `${API_USERROLE_ENDPOINT}/${dealerId}/${productId}/roles`,
      providesTags: ["UserRole"],
    }),

    deleteUserRole: builder.mutation<any, DeleteUserRole>({
      query: (request) => ({
        url: `${API_USERROLE_ENDPOINT}`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: ["UserRole"],
    }),
  }),
});

export const {
  useAssignUserRoleMutation,
  useGetUserRolesQuery,
  useUnassignUserRoleMutation,
  useGetUserRolesByDealerAndProductQuery,
  useDeleteUserRoleMutation,
} = userRoleApi;

import { FC } from "react";
import {
  LinearProgress,
  Typography,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  Table,
} from "@mui/material";
import {
  GridRowSelectionModel,
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import {
  UserFilterColumn,
  FilterOperator,
  RoleFilterColumn,
  ProductFilterColumn,
} from "../../constants/enums";
import { useDensity } from "../../app/features/apiSlice";

export interface ServerGridProps {
  rows: any[];
  columns: GridColDef<any, any, any>[];
  rowCount?: number;
  isLoading?: boolean;
  onFilterChange?: (model: GridFilterModel) => void;
  filterColumn?: UserFilterColumn | RoleFilterColumn | ProductFilterColumn;
  setFilterColumn?: (value: any) => void;
  filterOperator?: FilterOperator;
  setFilterOperator?: (value: FilterOperator) => void;
  filterValue?: string;
  setFilterValue?: (value: string) => void;
  onSortModelChange?: (model: GridSortModel) => void;
  paginationModel?: PaginationModel;
  setPaginationModel?: (model: PaginationModel) => void;
  rowSelectionModel?: GridRowSelectionModel;
  setRowSelectionModel?: (model: GridRowSelectionModel) => void;
  pageNumbers?: number[][];
  totalPages?: number;
  keepNonExistentRowsSelected?: boolean;
  getRowHeight?:
    | ((params: GridRowHeightParams) => GridRowHeightReturnValue)
    | undefined;
  hiddenColumns?: string[]; // Hide columns by default
  columnVisibilityModel?: GridColumnVisibilityModel;
  onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel) => void;
  usageType?: string;
}

const ServerGrid: FC<ServerGridProps> = (props) => {
  const density = useDensity();

  if (props.isLoading) {
    return <LinearProgress />;
  }

  if (!props.isLoading && props.rows.length === 0) {
    return (
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={props.columns.length} align="center">
                <Typography>
                  No {props.usageType ? props.usageType : "Rows"} Available
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <div
      style={{
        height: props.usageType === "Product" ? "600px" : "500px",
        width: "100%",
      }}
    >
      <DataGrid
        rows={props.rows}
        columns={
          props?.hiddenColumns && props.hiddenColumns.length > 0
            ? props.columns.filter(
                (column) => !props.hiddenColumns?.includes(column.field)
              )
            : props.columns
        }
        getRowHeight={props?.getRowHeight}
        columnVisibilityModel={props?.columnVisibilityModel}
        onColumnVisibilityModelChange={props?.onColumnVisibilityModelChange}
        sortingMode={props?.onSortModelChange ? "server" : "client"}
        onSortModelChange={(sortModel) => {
          if (props.onSortModelChange) {
            props.onSortModelChange(sortModel); // Trigger the callback passed from Products
          }
        }}
        filterMode={props?.onFilterChange ? "server" : "client"}
        onFilterModelChange={props?.onFilterChange}
        pagination
        paginationMode={props?.paginationModel ? "server" : "client"}
        paginationModel={props?.paginationModel}
        rowCount={props?.rowCount}
        pageSizeOptions={[25, 50, 75, 100]}
        onPaginationModelChange={props.setPaginationModel}
        rowSelectionModel={props.rowSelectionModel}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          props.setRowSelectionModel &&
            props.setRowSelectionModel(newRowSelectionModel);
        }}
        loading={props.isLoading}
        keepNonExistentRowsSelected={props.keepNonExistentRowsSelected || false}
        disableColumnMenu
        density={density}
        sx={{
          bgcolor: "white",
          filter: props.isLoading ? "blur(1px)" : "none",
          transition: "filter 0.3s ease",
          "& .MuiDataGrid-columnHeader": {
            bgcolor: "#eaecee",
          },
        }}
        localeText={{
          noRowsLabel: props.usageType
            ? `No ${props.usageType} Available.`
            : "No Rows",
        }}
      />
    </div>
  );
};

export default ServerGrid;

import { configureStore } from "@reduxjs/toolkit";
import { errorLoggerMiddleware } from "../middlewares/errorLoggerMiddleware";
import { idmsApi } from "./services/api";
import { everestApi } from "./services/customAuthApi";
import reducer from "./reducer";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 6,
  storage,
  whitelist: ["auth", "user", "role", "product", "feature", "api", "salesTax"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(errorLoggerMiddleware)
      .concat(idmsApi.middleware)
      .concat(everestApi.middleware),
  // devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

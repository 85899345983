import * as yup from "yup";

export const createUserValidationSchema = yup.object<CreateUser>({
  dealerId: yup
    .number()
    .moreThan(0, "Dealer Id must be greater than 0")
    .required("Dealer Id is required"),

  username: yup.string().required("Username is required"),

  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),

  productIds: yup
    .array()
    .of(yup.string().trim().strict(true).min(1))
    .min(1, "At least one product is required")
    .required("Product is required"),

  email: yup.string().email("Enter a valid email"),

  fullName: yup.string(),

  phone: yup.string(),
});

export const updateUserValidationSchema = yup.object<UpdateUser>({
  userName: yup.string().required("UserName is required"),

  dealerId: yup
    .number()
    .moreThan(0, "Dealer Id must be greater than 0")
    .required("Dealer Id is required"),

  userId: yup.number().required("User Id is required"),

  // fullName: yup.string(),

  phone: yup.string(),

  email: yup
    .string()
    .nullable()
    .when("emailConfirmed", {
      is: (emailConfirmed: boolean) => emailConfirmed === true,
      then: () =>
        yup
          .string()
          .email()
          .required("Email is required when Email Confirmed is true"),
    }),

  emailConfirmed: yup.boolean(),

  lockoutEnd: yup
    .date()
    .nullable()
    .when("lockoutEnabled", {
      is: (lockoutEnabled: boolean) => lockoutEnabled === true,
      then: () =>
        yup
          .date()
          .required("Lockout End is required when Lockout Enabled is true"),
    }),

  lockoutEnabled: yup.boolean(),

  isDmsAdmin: yup.boolean(),
});

export const createRoleValidationSchema = yup.object<CreateRole>({
  name: yup.string().required("Role Name required"),
  dmsDealerId: yup
    .number()
    .moreThan(0, "Dealer Id must be greater than 0")
    .required("Dealer Id is required"),
});

export const createProductValidationSchema = yup.object<CreateProduct>({
  productName: yup.string().required("Product Name is required").max(200, "Product Name must be at most 200 characters long"),
  productType: yup.string().required("Product Type is required"),
  description: yup.string().required("Description is required").max(200, "Description must be at most 200 characters long"),
});

export const createFeatureValidationSchema = yup.object<CreateProduct>({
  productFeatureName: yup.string().required("Product feature name is required").max(200, "Feature Name must be at most 200 characters long"),
  description: yup.string().required("Description is required").max(200, "Description must be at most 200 characters long"),
});

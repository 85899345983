import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useGetRoleUsersQuery } from "../app/services/roleUser";
import { useUnassignUserRoleMutation } from "../app/services/userRole";
import { getUserColumns } from "../util/gridColumns/userColumns";
import "./common/Common.css";
import ServerGrid, { ServerGridProps } from "./common/ServerGrid";
import CancelIcon from "@mui/icons-material/Cancel";
import { getApiErrorMessage } from "../util/getApiErrorMessage";

const RoleUsers: FC = () => {
  const { roleId } = useParams();
  const { roleName, dmsDealerId } = useLocation().state as any;
  const roleUserColumns = prepareColumns();
  const [rows, setRows] = useState<UserInformation[]>([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState<UnassignUserRole>({
    roleId: [roleId as string],
    userId: "",
  });

  /*
   * API calls
   * */

  const {
    data: roleUsers,
    isLoading: roleUsersIsLoading,
    isError: roleUsersIsError,
    error: roleUsersError,
    isSuccess: roleUsersIsSuccess,
  } = useGetRoleUsersQuery(roleId as string);

  const [
    doUnassignUserRole,
    {
      isLoading: unassignUserRoleIsLoading,
      isError: unassignUserRoleIsError,
      error: unassignUserRoleError,
      isSuccess: unassignUserRoleIsSuccess,
    },
  ] = useUnassignUserRoleMutation();

  /*
   * Effects
   * */

  useEffect(() => {
    if (roleUsersIsSuccess) setRows(roleUsers?.data as UserInformation[]);
  }, [roleUsers, roleUsersIsSuccess]);

  useEffect(() => {
    if (roleUsersIsError) {
      setSnackbarMessage(
        getApiErrorMessage({
          error: {
            ...roleUsersError,
          },
        })
      );
      setIsSnackbarOpen(true);
    }
  }, [roleUsersIsError]);

  /*
   * Handlers
   * */

  const handleSnackbarClose = () => setIsSnackbarOpen(false);

  const handleUnassignUserRoleDialogOpen = (userId: string) => {
    setSelectedUserRole((prev) => ({
      ...prev,
      userId,
    }));
    setConfirmationDialogOpen(true);
  };

  function prepareColumns() {
    const setActions = (params: GridRowParams<any>) => {
      return [
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Unassign Role"
          title="Unassign Role"
          onClick={() => handleUnassignUserRoleDialogOpen(params.row.id)}
          disabled={unassignUserRoleIsLoading}
        />,
      ];
    };

    return getUserColumns(setActions);
  }

  const handleConfirm = async () => {
    setConfirmationDialogOpen(false);
    if (selectedUserRole.userId) {
      var payload = await doUnassignUserRole(selectedUserRole);

      setSnackbarMessage(getApiErrorMessage(payload, ""));
      setIsSnackbarOpen(true);
    }
  };

  const serverGridProps: ServerGridProps = {
    rows,
    columns: roleUserColumns,
    rowCount: roleUsers?.length,
    isLoading: roleUsersIsLoading,
    keepNonExistentRowsSelected: true,
    hiddenColumns: [
      "dealerId",
      "dmsDealerId",
      "userId",
      "createdDateTime",
      "updateDateTime",
      "lastLoginTime",
      "isDmsAdmin",
    ],
  };

  return (
    <Box id="base-padding">
      {snackbarMessage !== "" && (
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            severity={unassignUserRoleIsSuccess ? "success" : "error"}
            onClose={handleSnackbarClose}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}

      <Box mx={3}>
        <Box display="flex">
          <Typography variant="h5" gutterBottom>
            User(s) of
          </Typography>
          <Box width={8} />
          <Typography variant="h5" gutterBottom color={"#3f51b5"}>
            {roleName}
          </Typography>
        </Box>

        {unassignUserRoleIsLoading && <LinearProgress />}

        <ServerGrid {...serverGridProps} />
      </Box>

      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      >
        <Box>
          <DialogTitle>
            Are you sure you want to unassign this role?
          </DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={handleConfirm}>
              Yes
            </Button>
            <Button
              variant="outlined"
              onClick={() => setConfirmationDialogOpen(false)}
            >
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default RoleUsers;

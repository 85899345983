const apiVersion = "v1";
export const API_TOKEN_ENDPOINT = `/api/${apiVersion}/Account/token`;
export const API_VALIDATETOKEN_ENDPOINT = `/api/${apiVersion}/Account/validateToken`;
export const API_REFRESHTOKEN_ENDPOINT = `/api/${apiVersion}/Account/refreshToken`;

export const API_USER_ENDPOINT = `/api/${apiVersion}/User`;

export const API_PRODUCT_ENDPOINT = `/api/${apiVersion}/Product`;

export const API_ROLE_ENDPOINT = `/api/${apiVersion}/Role`;
export const API_PERMISSION_ENDPOINT = `/api/${apiVersion}/Permission`;
export const API_USERROLE_ENDPOINT = `/api/${apiVersion}/UserRole`;

export const API_USERPRODUCT_ENDPOINT = `/api/${apiVersion}/UserProduct`;

export const API_SALES_TAX_ENGINE_ENDPOINT = `/api/Query`;

export const API_SALES_TAX_ENGINE_BASE_URL = `/api/SalesTax`;
export const API_DEALER_ENDPOINT = `/api/${apiVersion}/Dealership`;

import { FC, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  useGetCompanyCodeMutation,
  useGetSalesTaxEngineConfigsQuery,
  useTestAvaleraConnectionMutation,
  useUpdateSalesTaxEngineConfigsMutation,
} from "../../../app/services/salestax";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useGetDealerId } from "../../../app/features/salestaxSlice";
import TaxCodeMapping from "./TaxCodeMapping";
import { SaveAvaTaxSettingsCallback } from "../../../util/DmsTrigger";
import { useLocation } from "react-router-dom";
import TransactionLog from "./TransactionLog";

interface DealerConfigs {
  Enabled: boolean;
  Environment: string;
  AccountNumber: string;
  LicenseKey: string;
  CompanyCode: string;
  CommitDocument: boolean;
  EnableLogging: boolean;
  ValidateAddress: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AvaleraTaxEngine: FC = () => {
  const DealerId = useGetDealerId();
  const urlParams = new URLSearchParams(window.location.search);
  const dealerGuid = urlParams.get("dealerGuid");

  const location = useLocation();
  const isDashboardRoute = location.pathname.includes("/dashboard");

  const [tabValue, setTabValue] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [avaleraConfigsForm, setAvaleraConfigsForm] = useState<DealerConfigs>({
    Enabled: false,
    Environment: "",
    AccountNumber: "",
    LicenseKey: "",
    CompanyCode: "",
    CommitDocument: false,
    EnableLogging: false,
    ValidateAddress: false,
  });

  /*
   * API calls
   * */

  const requestObject = {
    query: "SalesTax.AvalaraSetting",
    queryBody: { DealerGUID: dealerGuid },
  };

  //* Fetch Dealer Configurations API
  const {
    data: configs,
    isLoading: configsIsLoading,
    isSuccess: configsIsSuccess,
    isError: configsIsError,
    error: configsError,
    refetch: refetchConfigs,
  } = useGetSalesTaxEngineConfigsQuery(requestObject, {
    refetchOnMountOrArgChange: false,
  });

  //* Get Company-Code API
  const [
    getCompanyCode,
    {
      data: companyCodeData,
      error: companyCodeError,
      isError: companyCodeIsError,
      isLoading: companyCodeIsLoading,
      isSuccess: companyCodeIsSuccess,
    },
  ] = useGetCompanyCodeMutation();

  //* Test Connection API
  const [
    testConnection,
    {
      data: testConnectionData,
      error: testConnectionError,
      isError: testConnectionIsError,
      isLoading: testConnectionIsLoading,
      isSuccess: testConnectionIsSuccess,
    },
  ] = useTestAvaleraConnectionMutation();

  //* Save/Update Configurations API
  const [
    updateSalesTaxEngineConfigs,
    {
      data: updateConfigs,
      error: updateConfigsError,
      isError: updateConfigsIsError,
      isLoading: updateConfigsIsLoading,
      isSuccess: updateConfigsIsSuccess,
    },
  ] = useUpdateSalesTaxEngineConfigsMutation();

  /*
   * Effects
   * */

  // Fetch Dealer Configurations effect
  useEffect(() => {
    // handle 400 & 500 errors.
    if (configsIsError) {
      toast.error("Internal Server Error 500, Please Check the Dealer Id", {
        toastId: 101,
        autoClose: 5000,
      });
    }

    // Populate the Form state with Fetch Configuration response.
    if (configsIsSuccess && configs) {
      setAvaleraConfigsForm((prevSettings) => ({
        ...prevSettings,
        Enabled: configs.Enabled,
        Environment: configs.Environment,
        AccountNumber: configs.AccountNumber,
        LicenseKey: configs.LicenseKey,
        CompanyCode: configs.CompanyCode,
        CommitDocument: configs.CommitDocument,
        EnableLogging: configs.EnableLogging,
        ValidateAddress: configs.ValidateAddress,
      }));
    }
  }, [configs, configsIsSuccess, configsIsError]);

  useEffect(() => {
    // Check if dealer configs are not exist.
    // if (configs && configs.Response) {
    //   toast.error(`${configs.Response}, Invalid dealerId`, {
    //     toastId: 102,
    //     autoClose: 5000,
    //   });
    // }

    // Fetch Company-Code if dealer configs are exist.
    if (configs && !configs.Response)
      getCompanyCode({
        DealerId: DealerId,
        AccountID: configs.AccountNumber,
        LicenseKey: configs.LicenseKey,
        IsSandboxEnvironment: configs.Environment === "Sandbox",
      });
  }, [configs]);

  /*
   * Handlers
   * */

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setTabValue(index);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setAvaleraConfigsForm((prevSettings) => ({
      ...prevSettings,
      [name]: checked,
    }));
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAvaleraConfigsForm((prevSettings) => ({
      ...prevSettings,
      Environment: (event.target as HTMLInputElement).value,
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAvaleraConfigsForm((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleTestConnection = async () => {
    let response = await testConnection({
      DealerId : DealerId,
      AccountID: avaleraConfigsForm.AccountNumber,
      LicenseKey: avaleraConfigsForm.LicenseKey,
      IsSandboxEnvironment: avaleraConfigsForm.Environment === "Sandbox",
    });

    // If authentication success, then Fetch Company-Code if dealer comes first time.
    if ((response as any)?.data?.authenticated) {
      toast.success("Authentication successfull", {
        toastId: 103,
        autoClose: 3000,
      });

      if (!configs) {
        getCompanyCode({
          DealerId : DealerId,
          AccountID: avaleraConfigsForm.AccountNumber,
          LicenseKey: avaleraConfigsForm.LicenseKey,
          IsSandboxEnvironment: avaleraConfigsForm.Environment === "Sandbox",
        });
      }
    } else {
      toast.error("Authentication failed", {
        toastId: 104,
        autoClose: 3000,
      });
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const requestBody = {
      query: "SalesTax.AvalaraSetting",
      queryBody: {
        ...avaleraConfigsForm,
        DealerId,
      },
    };

    try {
      const res = await updateSalesTaxEngineConfigs(requestBody);

      if ((res as any).data.Response) {
        toast.success("Update Configurations Successfully");
        refetchConfigs();
        SaveAvaTaxSettingsCallback(avaleraConfigsForm);
      }
    } catch (error) {
      if (updateConfigsIsError || configs.Response) {
        toast.error(configs.Response);
        SaveAvaTaxSettingsCallback(configs.Response);
      } else {
        // Handle any other unexpected errors
        console.error("Unexpected error:", error);
        toast.error("An unexpected error occurred");
        SaveAvaTaxSettingsCallback("An unexpected error occurred");
      }
    }
  };

  //* Check if any of the required fields for save configs API are empty
  const isFormComplete = () => {
    return (
      avaleraConfigsForm.Environment !== "" &&
      avaleraConfigsForm.AccountNumber !== "" &&
      avaleraConfigsForm.LicenseKey !== "" &&
      avaleraConfigsForm.CompanyCode !== ""
    );
  };

  //* Check if any of the required fields for TestConnection API are empty
  const isTestConnectionVisibal = () => {
    return (
      avaleraConfigsForm.Environment !== "" &&
      avaleraConfigsForm.AccountNumber !== "" &&
      avaleraConfigsForm.LicenseKey !== ""
    );
  };

  //* Check if error then display error msg otherwise render component
  const isComponentRender = () => {
    return configs && configs.Response;
  };

  const handleTextClick = () => setIsDrawerOpen(true);

  const handleCloseDrawer = () => setIsDrawerOpen(false);

  return (
    <>
      <Box sx={{ width: "100%", bgcolor: "primary" }}>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Avalara Tax Engine" {...a11yProps(0)} />
          {/* <Tab label="Tax Code Mapping" {...a11yProps(1)} />
          <Tab label="Transaction Log" {...a11yProps(2)} /> */}
        </Tabs>

        <CustomTabPanel value={tabValue} index={1}>
          <TaxCodeMapping />
        </CustomTabPanel>

        <CustomTabPanel value={tabValue} index={2}>
          <TransactionLog />
        </CustomTabPanel>

        <CustomTabPanel value={tabValue} index={0}>
          {isComponentRender() ? (
            <h4>{configs.Response}</h4>
          ) : (
            <>
              {/* Add the Backdrop component */}
              <Backdrop
                open={configsIsLoading}
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              <Typography
                sx={{
                  fontWeight: "bold",
                  marginBottom: "15px",
                }}
              >
                Avalara Tax Settings
              </Typography>

              <Typography
                sx={{
                  display: "inline",
                  marginBottom: "5px",
                  fontSize: "13px",
                  color: "#04a6c7",
                  cursor: "pointer",
                }}
              >
                <span
                  onClick={handleTextClick}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  style={{
                    textDecoration: isHovered ? "underline" : "none",
                  }}
                >
                  API Guide
                </span>
              </Typography>

              <form>
                <Stack direction="row" alignItems="center">
                  <Typography variant="body2" sx={{ mr: 2, minWidth: 150 }}>
                    Enable AvaTax:
                  </Typography>

                  <Checkbox
                    required
                    name="Enabled"
                    checked={avaleraConfigsForm.Enabled}
                    onChange={handleCheckBox}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Typography variant="body2" sx={{ mr: 3, minWidth: 150 }}>
                    Environment Mode*:
                  </Typography>

                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={avaleraConfigsForm.Environment}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="Sandbox"
                        control={<Radio />}
                        label="Sandbox"
                      />
                      <FormControlLabel
                        value="Production"
                        control={<Radio />}
                        label="Production"
                      />
                    </RadioGroup>
                  </FormControl>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Typography variant="body2" sx={{ mr: 2, minWidth: 150 }}>
                    Account/Username*:
                  </Typography>

                  <TextField
                    required
                    name="AccountNumber"
                    value={avaleraConfigsForm.AccountNumber}
                    onChange={handleChange}
                    id="outlined-size-small"
                    size="small"
                    sx={{ width: "20%" }}
                  />
                </Stack>

                <Stack direction="row" alignItems="center" my={2}>
                  <Typography variant="body2" sx={{ mr: 2, minWidth: 150 }}>
                    Key/Password*:
                  </Typography>

                  <FormControl sx={{ width: "20%" }} variant="outlined">
                    <OutlinedInput
                      size="small"
                      name="LicenseKey"
                      value={avaleraConfigsForm.LicenseKey}
                      onChange={handleChange}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Stack>

                <Stack direction="row" alignItems="center" my={3}>
                  <Typography variant="body2" sx={{ mr: 2, minWidth: 150 }}>
                    Test Connection:
                  </Typography>

                  <Button
                    variant="outlined"
                    onClick={handleTestConnection}
                    disabled={
                      !isTestConnectionVisibal() || testConnectionIsLoading
                    }
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    Test Connection
                    {testConnectionIsLoading && (
                      <CircularProgress
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: -12,
                          marginLeft: -12,
                          color: "secondary",
                        }}
                        size={24}
                      />
                    )}
                  </Button>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Typography variant="body2" sx={{ mr: 2, minWidth: 150 }}>
                    Company Code*:
                  </Typography>

                  <TextField
                    required
                    name="CompanyCode"
                    id="outlined-size-small"
                    size="small"
                    select
                    sx={{ width: "20%" }}
                    value={avaleraConfigsForm.CompanyCode}
                    onChange={handleChange}
                  >
                    {companyCodeIsLoading && (
                      <MenuItem value="">Loading...</MenuItem>
                    )}
                    {companyCodeIsError && (
                      <MenuItem value="">
                        Error loading options. Please refresh the page.
                      </MenuItem>
                    )}

                    <MenuItem value="">None</MenuItem>
                    {companyCodeData?.value?.map((item: any, index: number) => (
                      <MenuItem key={index} value={item.companyCode}>
                        {item.companyCode}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Typography variant="body2" sx={{ mr: 2, minWidth: 150 }}>
                    Commit Documents:
                  </Typography>

                  <Checkbox
                    name="CommitDocument"
                    checked={avaleraConfigsForm.CommitDocument}
                    onChange={handleCheckBox}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Typography variant="body2" sx={{ mr: 2, minWidth: 150 }}>
                    Enable Logging:
                  </Typography>

                  <Checkbox
                    name="EnableLogging"
                    checked={avaleraConfigsForm.EnableLogging}
                    onChange={handleCheckBox}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Stack>

                <Stack direction="row" alignItems="center">
                  <Typography variant="body2" sx={{ mr: 2, minWidth: 150 }}>
                    Validate Address:
                  </Typography>

                  <Checkbox
                    name="ValidateAddress"
                    checked={avaleraConfigsForm.ValidateAddress}
                    onChange={handleCheckBox}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Stack>

                <hr />

                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!isFormComplete() || updateConfigsIsLoading}
                  sx={{
                    textTransform: "capitalize",
                    marginTop: 2,
                    marginBottom: 2,
                    width: "150px",
                  }}
                >
                  Save
                  {updateConfigsIsLoading && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: -12,
                        marginLeft: -12,
                        color: "secondary",
                      }}
                      size={24}
                    />
                  )}
                </Button>
              </form>
            </>
          )}
        </CustomTabPanel>
      </Box>

      {/********** DRAWER **********/}
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleCloseDrawer}>
        <div
          style={{
            width: 500,
            padding: 16,
            paddingTop: isDashboardRoute ? "80px" : "16px",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: "#04a6c7", mb: 1 }}
          >
            API Guide
          </Typography>

          <Typography variant="body1" fontSize={11}>
            Avalara AvaTax is a cloud-based solution automating transaction tax
            calculations and the tax filing process. Avalara provides real-time
            tax calculation using tax content from more than 12,000 US taxing
            jurisdictions and over 200 countries, insuring your transaction tax
            is calculated based on the most current tax rules.
          </Typography>

          <Typography
            variant="body1"
            fontSize={13}
            fontWeight="bold"
            mt={1}
            sx={{ color: "#04a6c7" }}
          >
            Configure Avalara AvaTax:
          </Typography>

          <Typography variant="body1" fontSize={11} mt={1} mb={1}>
            After installing the AvaTax integration, you need to configure the
            integration
          </Typography>

          <img alt="img-01" src="/images/ava-tax-settings.png" width="100%" />

          <Typography variant="body1" fontSize={11}>
            Enter your Avalara AvaTax Credentials:
          </Typography>

          <ul style={{ fontSize: "11px" }}>
            <li>
              <span style={{ fontWeight: "bold" }}>
                Disable Tax Calculation:&nbsp;
              </span>
              Turn off the AvaTax calculation service.
            </li>

            <li>
              <span style={{ fontWeight: "bold" }}>
                Environment Mode:&nbsp;
              </span>
              Select the specific environment mode.
            </li>

            <li>
              <span style={{ fontWeight: "bold" }}>Account Number:&nbsp;</span>
              Provided during your AvaTax account activation process.
            </li>

            <li>
              <span style={{ fontWeight: "bold" }}>License Key:&nbsp;</span>
              Provided during your AvaTax account activation process.
            </li>

            <li>
              <b>Test Connect button:</b> Click to test the connection.
            </li>

            <li>
              <span style={{ fontWeight: "bold" }}>Company Code:&nbsp;</span>
              Company profile identifier in the AvaTax Admin Console.
            </li>

            <li>
              <span style={{ fontWeight: "bold" }}>
                Enable Document Committing:&nbsp;
              </span>
              Enables tax document submission to Avalara’s AvaTax service for
              record keeping. With this setting enabled, transactions will be
              posted and committed to the AvaTax Admin Console.
            </li>

            <li>
              <span style={{ fontWeight: "bold" }}>Enable Logging:&nbsp;</span>
              Log detailed transaction activity for troubleshooting purposes.
              Enable only when instructed by support.
            </li>

            <li>
              <span style={{ fontWeight: "bold" }}>
                Address Validation:&nbsp;
              </span>
              Option to enable or disable address validation.
            </li>
          </ul>

          <Typography
            variant="body1"
            fontSize={13}
            fontWeight="bold"
            my={1}
            sx={{ color: "#04a6c7" }}
          >
            Avalara Tax Code Mapping:
          </Typography>

          <img alt="img-02" src="/images/tax-code-mapping.png" width="100%" />

          <hr />

          <Typography variant="body1" fontSize={11} my={1}>
            Configure the AvaTax Code Mapping:
          </Typography>

          <ul style={{ fontSize: "11px" }}>
            <li>
              <span style={{ fontWeight: "bold" }}>Category:&nbsp;</span>
              Select the category from dropdown to apply the Tax Code.
            </li>

            <li>
              <span style={{ fontWeight: "bold" }}>Tax Code:&nbsp;</span>
              Select the specific Tax Code to the category.
            </li>

            <li>Click on the save to apply the changes.</li>
          </ul>

          <Typography
            variant="body1"
            fontSize={13}
            fontWeight="bold"
            my={1}
            sx={{ color: "#04a6c7" }}
          >
            Validate a customer address:
          </Typography>

          <img alt="img-02" src="/images/address-validation.jpg" width="100%" />

          <hr />

          <ul style={{ fontSize: "11px" }}>
            <li>Enter the customer address details.</li>

            <li>
              Click on the <b>Validate Address</b> button.
            </li>

            <li>
              Review the returned validated address, click the Update button to
              update the customer record with the returned address.
            </li>
          </ul>
        </div>
      </Drawer>
    </>
  );
};

export default AvaleraTaxEngine;

import { idmsApi } from "./api";
import { API_USERROLE_ENDPOINT } from "../../constants/endpoints";

const roleUserApi = idmsApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoleUsers: builder.query<any, string>({
      query: (roleId) => `${API_USERROLE_ENDPOINT}/${roleId}/users`,
      providesTags: ["RoleUser"],
    }),
  }),
});

export const { useGetRoleUsersQuery } = roleUserApi;

import { idmsApi } from "./api";
import { API_PERMISSION_ENDPOINT, API_ROLE_ENDPOINT } from "../../constants/endpoints";
import { convertObjectToQueryParams } from "../../util/convertObjectToQueryParams";

const roleApi = idmsApi.injectEndpoints({
  endpoints: (builder) => ({
    createRole: builder.mutation<any, CreateRole>({
      query: (request) => ({
        url: API_ROLE_ENDPOINT,
        method: "POST",
        body: request,
      }),
    }),

    assignRolePermissions: builder.mutation<any, any>({
      query: (request) => ({
        url: API_PERMISSION_ENDPOINT,
        method: "POST",
        body: request,
      }),
    }),

    getRoles: builder.query<any, GetRolesSearchCriteria>({
      query: (getRolesSearchCriteria) =>
        `${API_ROLE_ENDPOINT}?${convertObjectToQueryParams(
          getRolesSearchCriteria
        )}`,
    }),

    getRolePermissions: builder.query<any, RolePermissionsDto>({
      query: (permissionsDto) =>
        `${API_PERMISSION_ENDPOINT}?${convertObjectToQueryParams(
          permissionsDto
        )}`,
    }),

    updateRole: builder.mutation<any, UpdateRoleReq>({
      query: ({ roleId, updatedRoleObj }) => ({
        url: `${API_ROLE_ENDPOINT}/${roleId}`,
        method: "PUT",
        body: updatedRoleObj,
      }),
    }),

    deleteRole: builder.mutation<any, string>({
      query: (roleId) => ({
        url: `${API_ROLE_ENDPOINT}/${roleId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetRolePermissionsQuery,
  useCreateRoleMutation,
  useAssignRolePermissionsMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = roleApi;

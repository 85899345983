import { Outlet } from "react-router-dom";
import Header from "../common header/Header";
import Sidebar from "./Sidebar/index";
import { Box, CssBaseline, Toolbar } from "@mui/material";

const DashboardLayout = () => {
  return (
    <Box>
      <CssBaseline />

      {/*********** Header **********/}
      <Header />

      {/******** Side Navbar ********/}
      <Sidebar />

      {/*********** Main ***********/}
      <Box component="main" sx={{ p: 2, pl: 8 }}>
        <Toolbar />

        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardLayout;

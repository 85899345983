import { Navigate } from "react-router-dom";
import { useCheckAuthToken } from "../hooks/useCheckAuthToken";
import { ReactElement } from "react";

interface PublicRouteProps {
    element: ReactElement;
  }
  
  const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
    const isAuthenticated = useCheckAuthToken();
    
    return isAuthenticated ? <Navigate to="/dashboard/app" /> : element;
  };
  
  export default PublicRoute;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../hooks";

const authInitialState: Auth = {
  username: "",
  password: "",
  token: {
    accessToken: "",
    expiresIn: 0,
    refreshToken: "",
    tokenType: "",
    scope: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<Auth>) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
      state.token = action.payload.token;
    },
    setToken: (state, action: PayloadAction<Token>) => {
      state.token = action.payload;
    },
    logout: () => authInitialState,
  },
});

export const { setCredentials, setToken, logout } = authSlice.actions;

export default authSlice.reducer;

export const useCurrentAuthenticatedUser = () =>
  useAppSelector((state) => state.auth);

import {
  API_REFRESHTOKEN_ENDPOINT,
  API_TOKEN_ENDPOINT,
  API_VALIDATETOKEN_ENDPOINT,
} from "../../constants/endpoints";
import { idmsApi } from "./api";

const authApi = idmsApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (request) => ({
        url: API_TOKEN_ENDPOINT,
        method: "POST",
        body: request,
      }),
      // providesTags: (result, error, arg) =>
      //   result
      //     ? [...result.map(({ id }) => ({ type: "Auth" as const, id })), "Auth"]
      //     : ["Auth"],
    }),

    validateToken: builder.query({
      query: () => API_VALIDATETOKEN_ENDPOINT,
    }),

    refreshToken: builder.mutation<
      LoginResponse,
      RequireOnly<RefreshToken, "refreshToken">
    >({
      query: (request) => ({
        url: API_REFRESHTOKEN_ENDPOINT,
        method: "POST",
        body: request,
      }),
      // invalidatesTags: ["Auth"],
    }),
  }),
});

export const {
  useLoginMutation,
  useValidateTokenQuery,
  useRefreshTokenMutation,
} = authApi;

import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useAssignProductMutation,
  useGetDealersQuery,
  useGetDmsListQuery,
  useUnAssignProductMutation,
} from "../app/services/dealer";
import { getApiErrorMessage } from "../util/getApiErrorMessage";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledTableHead } from "../constants/variables";

const ManageDealer: React.FC<ProductFormProps> = ({ selectedProductId }) => {
  const [dealersData, setDealersData] = useState<any[]>([]);
  const [currentDealers, setCurrentDealers] = useState<any[]>([]);
  const [dmsListData, setDmsListData] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [dealerCase, setDealerCase] = useState("All");
  const [filter, setFilter] = useState<GetDealership>({
    productId: selectedProductId,
    dmsTypes: "Ideal",
    searchText: "",
    case: "All",
  });
  const initialDealerIds: string[] = [];
  const [assignedProduct, setAssignProduct] = useState<DealerAssignProduct>({
    productId: selectedProductId ?? "",
    dealerIds: initialDealerIds,
  });
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [unassignDialogOpen, setUnAssignnDialogOpen] = useState(false);
  const [unassignedDealer, setUnAssignedDealer] = useState<any>();
  const [pageSize, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [snackbarIsError, setSnackbarIsError] = useState<boolean>(false);

  /*
   * API calls
   * */
  const [
    assignProduct,
    { isLoading: AssignProductIsLoading, isSuccess: AssignProductIsSuccess },
  ] = useAssignProductMutation();

  const [
    unAssignProduct,
    {
      isLoading: UnAssignProductIsLoading,
      isSuccess: UnAssignProductIsSuccess,
    },
  ] = useUnAssignProductMutation();

  const { data: dmsList, isSuccess: dmsIsSuccess } = useGetDmsListQuery();

  const {
    data: dealers,
    isFetching: dealersIsFetching,
    isError: dealersIsError,
    refetch: refetchDealers,
  } = useGetDealersQuery(
    { ...filter, page: page + 1, pageSize },
    {
      refetchOnMountOrArgChange: true, // Refetch data every time the component mounts or the arguments change
      refetchOnReconnect: true, // Refetch data when the network reconnects
      skip: false, // Ensure the query runs without skipping
    }
  );

  /*
   * Effects
   * */

  useEffect(() => {
    if (dmsList) {
      setDmsListData(dmsList);
    }
  }, [dmsList]);

  useEffect(() => {
    if (dealers) {
      setDealersData(dealers.data);
      setTotalCount(dealers.totalRecordsCount);
      setSelectAll(
        dealers.data.every((dealer: { dealerId: string }) =>
          assignedProduct.dealerIds.includes(dealer.dealerId)
        )
      );
    }
  }, [dealers, assignedProduct.dealerIds]);

  /*
   * Functions
   * */
  const handleSearchSubmit = (searchString: string) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      searchText: searchString,
      dmsTypes: filter.dmsTypes,
      page: page,
    }));
  };

  const toggleSelectAllDealers = (selectAll: boolean) => {
    setSelectAll(selectAll);

    if (selectAll) {
      // Add all dealer IDs on the current page to the selected list
      const newDealerIds = dealersData.reduce((acc, dealer) => {
        if (!assignedProduct.dealerIds.includes(dealer.dealerId)) {
          acc.push(dealer.dealerId);
        }
        return acc;
      }, assignedProduct.dealerIds.slice());
      setAssignProduct((prevState) => ({
        ...prevState,
        dealerIds: newDealerIds,
      }));
    } else {
      // Remove all dealer IDs on the current page from the selected list
      const remainingDealerIds = assignedProduct.dealerIds.filter(
        (id) => !dealersData.some((dealer) => dealer.dealerId === id)
      );
      setAssignProduct((prevState) => ({
        ...prevState,
        dealerIds: remainingDealerIds,
      }));
    }
  };

  const handleUnassignConfirm = async () => {
    setSnackbarIsError(false);
    if (unassignedDealer) {
      try {
        const unassignDealer: DealerUnAssignProduct = {
          productId: selectedProductId,
          dealerId: unassignedDealer.dealerId,
        };
        const payload = await unAssignProduct(unassignDealer).unwrap();
        setSnackbarMessage(
          getApiErrorMessage(payload, "Dealer Unassigned Successfully")
        );
        setUnAssignnDialogOpen(false);
        refetchDealers();
        setIsSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage(getApiErrorMessage(error, "Something went wrong"));
        setIsSnackbarOpen(true);
      }
    }
  };

  const handleSave = async () => {
    setSnackbarIsError(false);
    try {
      if (assignedProduct.dealerIds.length === 0) {
        setSnackbarIsError(true);
        setSnackbarMessage("Please select one or more dealer(s)");
        setIsSnackbarOpen(true);
        return;
      }

      const currentDealerIds = currentDealers.map((dealer) =>
        dealer.id.toString()
      );
      const uniqueDealerIds = currentDealerIds.filter(
        (id) => !assignedProduct.dealerIds.includes(id)
      );

      const newAssignedProduct = {
        ...assignedProduct,
        dealerIds: [...assignedProduct.dealerIds, ...uniqueDealerIds],
      };

      const payload = await assignProduct(newAssignedProduct).unwrap();
      setSnackbarMessage(
        getApiErrorMessage(
          payload,
          "Product assigned to the selected dealer(s) successfully"
        )
      );
      refetchDealers();

      // Clear the assigned dealers state
      setAssignProduct((prevState) => ({
        ...prevState,
        dealerIds: [],
      }));
      setIsSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(getApiErrorMessage(error, "Failed to assign product"));
      setIsSnackbarOpen(true);
    }
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    dealerId: string
  ) => {
    const checked = event.target.checked;
    if (checked) {
      setAssignProduct((prevState) => ({
        ...prevState,
        dealerIds: [...prevState.dealerIds, dealerId],
      }));
    } else {
      setAssignProduct((prevState) => ({
        ...prevState,
        dealerIds: prevState.dealerIds.filter((id) => id !== dealerId),
      }));
    }
  };
  const handleSnackbarClose = () => setIsSnackbarOpen(false);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    refetchDealers();
  };
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
    refetchDealers();
  };
  return (
    <Box
      sx={{
        marginLeft: { xs: 2, sm: 4, md: 10, lg: 20 },
        marginRight: { xs: 2, sm: 4, md: 10, lg: 20 },
      }}
    >
      {snackbarMessage !== "" && (
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity={
              (AssignProductIsSuccess || UnAssignProductIsSuccess) &&
              snackbarIsError === false
                ? "success"
                : "error"
            }
            onClose={handleSnackbarClose}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          mt: 2,
        }}
      >
        <TextField
          id="search"
          placeholder="Search by Dealer Name or ID"
          autoComplete="true"
          fullWidth
          size="small"
          margin="dense"
          value={searchQuery}
          onKeyUp={(e) => {
            if (e?.key === "Enter" && searchQuery.trim() !== "")
              handleSearchSubmit(searchQuery);
            else if (searchQuery.trim().length === 0) handleSearchSubmit("");
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            flexBasis: { xs: "100%", sm: "60%", md: "60%", lg: "50%" },
            backgroundColor: "background.paper",
            borderRadius: 1,
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "background.default",
            },
            "& .Mui-focused": {
              borderColor: "primary.main",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchQuery && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search"
                  onClick={() => {
                    setSearchQuery("");
                    handleSearchSubmit("");
                  }}
                  edge="end"
                  title="Clear search"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="dmsId"
          select
          label="Select DMS"
          fullWidth
          size="small"
          margin="dense"
          required
          value={filter.dmsTypes}
          onChange={(e) => {
            setPage(0);
            setFilter((prevFilter) => ({
              ...prevFilter,
              productId: selectedProductId,
              dmsTypes: e.target.value,
              searchText: searchQuery,
              page: 1,
            }));
          }}
          variant="outlined"
          sx={{
            flexBasis: { xs: "100%", sm: "30%", md: "30%", lg: "30%" },
            backgroundColor: "background.paper",
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "background.default",
            },
            "& .Mui-focused": {
              borderColor: "primary.main",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          {dmsIsSuccess && dmsListData.length > 0 ? (
            dmsListData.map((dmsItem) => (
              <MenuItem key={dmsItem.id} value={dmsItem.name}>
                {dmsItem.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" disabled>
              No DMS Found
            </MenuItem>
          )}
        </TextField>
        {/* Radio Buttons */}
        <RadioGroup
          row
          aria-label="dealer-case"
          name="dealer-case"
          value={dealerCase}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPage(0);
            setDealerCase((event.target as HTMLInputElement).value);
            setFilter((prevFilter) => ({
              ...prevFilter,
              page: 1,
              case: (event.target as HTMLInputElement).value,
            }));
          }}
          sx={{
            flex: { xs: "1 1 auto", sm: "0 0 auto" },
            justifyContent: { xs: "center", sm: "flex-start" },
            mt: { xs: 2, sm: 0 },
            mb: { xs: 2, sm: 0 },
          }}
        >
          <FormControlLabel value="All" control={<Radio />} label="All" />
          <FormControlLabel
            value="Assigned"
            control={<Radio />}
            label="Assigned"
          />
          <FormControlLabel
            value="Unassigned"
            control={<Radio />}
            label="Unassigned"
          />
        </RadioGroup>
      </Box>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          mt: 2,
        }}
      >
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "530px", display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ flex: "1 1 auto", overflowY: "auto" }}>
            <Table size="small" stickyHeader>
              <StyledTableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Dealer Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>DMS</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {dealersIsFetching ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : dealersIsError ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Dealers Found
                    </TableCell>
                  </TableRow>
                ) : dealersData.length > 0 ? (
                  dealersData.map((dealer: any) => (
                    <TableRow
                      key={dealer.dealerId}
                      sx={{
                        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                        "&:last-child td, &:last-child th": { border: 0 },
                        ".MuiTableCell-root": {
                          padding: "6px 16px",
                          fontSize: "0.875rem",
                        },
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={
                            assignedProduct.dealerIds.includes(
                              dealer.dealerId
                            ) || dealer.case === "Assigned"
                          }
                          disabled={dealer.case === "Assigned"}
                          onChange={(event) =>
                            handleCheckboxChange(event, dealer.dealerId)
                          }
                        />
                      </TableCell>
                      <TableCell>{dealer.dealerId}</TableCell>
                      <TableCell>{dealer.name}</TableCell>
                      <TableCell>{dealer.dmsType}</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          variant="outlined"
                          sx={{
                            color: "#007bff",
                            borderColor: "#007bff",
                            textTransform: "none",
                            "&:hover": {
                              borderColor: "#0056b3",
                              backgroundColor: "rgba(0, 123, 255, 0.1)",
                            },
                          }}
                          onClick={() => {
                            setUnAssignnDialogOpen(true);
                            setUnAssignedDealer(dealer);
                          }}
                          disabled={dealer.case === "Unassigned"}
                          startIcon={<DeleteIcon />}
                        >
                          Unassign
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Dealer found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
          <Box sx={{ flexShrink: 0 }}>
            <TablePagination
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 75, 100]}
            />
          </Box>
        </TableContainer>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          variant="contained"
          onClick={handleSave}
          size="small"
          disabled={AssignProductIsLoading}
        >
          Assign Selected Dealer(s)
          {AssignProductIsLoading && (
            <CircularProgress
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: -12,
                marginLeft: -12,
                color: "secondary",
              }}
              size={24}
            />
          )}
        </Button>
      </Box>
      {/*---------- Confirm Dialogs ----------*/}
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      >
        <Box>
          <DialogTitle>
            This will assign the product to all dealers. Are you sure you want
            to continue?
          </DialogTitle>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                toggleSelectAllDealers(true);
                setConfirmationDialogOpen(false);
              }}
            >
              Yes
            </Button>

            <Button
              variant="outlined"
              onClick={() => setConfirmationDialogOpen(false)}
            >
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={unassignDialogOpen}
        onClose={() => setUnAssignnDialogOpen(false)}
      >
        <Box>
          <DialogTitle>
            This will unassign the dealer{" "}
            {unassignedDealer ? `"${unassignedDealer.name}"` : "the dealer"}{" "}
            from the product. Are you sure you want to continue?
          </DialogTitle>

          <DialogActions>
            <Button variant="outlined" onClick={handleUnassignConfirm}>
              Yes
              {UnAssignProductIsLoading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                    color: "secondary",
                  }}
                  size={24}
                />
              )}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setUnAssignnDialogOpen(false);
                setUnAssignedDealer(undefined);
              }}
            >
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ManageDealer;

import { FC, useEffect, useState } from "react";
import AvaleraTaxEngine from "./components/AvaleraTaxEngine";
import {
  useDealerSalesTaxEngineTypeMutation,
  useGetSalesTaxEngineConfigsQuery,
  useGetSalesTaxEngineMutation,
} from "../../app/services/salestax";
import {
  Box,
  Breadcrumbs,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch } from "../../app/hooks";
import { setDealerId, useGetDealerId } from "../../app/features/salestaxSlice";
import { toast } from "react-toastify";
import BreadcrumbsControl from "../../components/common/BreadCrumbs";

const SaleTax: FC = () => {
  const dispatch = useAppDispatch();
  const DealerId = useGetDealerId();

  const urlParams = new URLSearchParams(window.location.search);
  const dealerGuid = urlParams.get("dealerGuid");

  const [taxEngineType, setTaxEngineType] = useState<number>(0);

  /*
   * API calls
   * */

  //* Fetch Dealer Configurations
  const requestObject = {
    query: "SalesTax.AvalaraSetting",
    queryBody: { DealerGUID: dealerGuid },
  };

  const {
    data: configs,
    isLoading: configsIsLoading,
    isSuccess: configsIsSuccess,
    isError: configsIsError,
    error: configsError,
    refetch: refetchConfigs,
  } = useGetSalesTaxEngineConfigsQuery(requestObject, {
    refetchOnMountOrArgChange: false,
  });

  // If DealerId exist, set it to global state
  useEffect(() => {
    if (configs && configs.DealerId) {
      dispatch(setDealerId(configs.DealerId));
    }

    // If dealer dose't exist, throw error
    if (configs && configs.Response) {
      toast.error(configs.Response);
    }
  }, [configs, configsIsSuccess]);

  const [
    getSalesTaxEngine,
    {
      data: salesTaxEngineData,
      error: getSalesTaxEngineError,
      isError: getSalesTaxEngineIsError,
      isLoading: getSalesTaxEngineIsLoading,
      isSuccess: getSalesTaxEngineIsSuccess,
    },
  ] = useGetSalesTaxEngineMutation();

  const [
    getDealerSalesTaxEngineType,
    {
      data: salesTaxEngineType,
      error: salesTaxEngineTypeError,
      isError: salesTaxEngineTypeIsError,
      isLoading: salesTaxEngineTypeIsLoading,
      isSuccess: salesTaxEngineTypeIsSuccess,
    },
  ] = useDealerSalesTaxEngineTypeMutation();

  /*
   * Effects
   * */

  useEffect(() => {
    const requestObject = {
      query: "SalesTax.TaxEngineTypes",
      queryBody: {},
    };

    getSalesTaxEngine(requestObject);
  }, []);

  // Get dealer tax engine type
  useEffect(() => {
    if (getSalesTaxEngineIsSuccess) {
      const requestObject = {
        query: "SalesTax.DealerSetting",
        queryBody: { DealerId },
      };

      getDealerSalesTaxEngineType(requestObject);
    }
  }, [getSalesTaxEngineIsSuccess]);

  useEffect(() => {
    if (salesTaxEngineType && salesTaxEngineTypeIsSuccess) {
      setTaxEngineType(salesTaxEngineType[0]?.TaxTypeId);
    }
  }, [salesTaxEngineTypeIsSuccess]);

  return (
    <Box id="base-padding" p={2}>
      <BreadcrumbsControl page={"Sales Tax"} />

      <Box mx={3}>
        <Box display={"flex"} my={2}>
          <TextField
            name="engineType"
            select
            label="Select Tax Engine"
            fullWidth
            required
            margin="dense"
            value={taxEngineType}
            onChange={(e) => setTaxEngineType(parseInt(e.target.value))}
            sx={{
              backgroundColor: "white",
              borderRadius: 7,
              "& .MuiInputBase-root": { borderRadius: 7 },
            }}
          >
            {getSalesTaxEngineIsLoading && (
              <MenuItem value={0}>Loading...</MenuItem>
            )}
            {getSalesTaxEngineIsError && (
              <MenuItem value={0}>
                Error loading options. Please refresh the page.
              </MenuItem>
            )}
            {(salesTaxEngineData as SalesTaxType[])?.map(
              (salesTaxEngine, index) => (
                <MenuItem key={index} value={salesTaxEngine.TaxEngineTypeId}>
                  {salesTaxEngine.Description}
                </MenuItem>
              )
            )}
          </TextField>
        </Box>

        <hr />

        {/********** Avalera Tax Engine **********/}
        {taxEngineType === 1 ? (
          <AvaleraTaxEngine />
        ) : (
          <Typography variant="body2">No tax settings to display!</Typography>
        )}
      </Box>
    </Box>
  );
};

export default SaleTax;

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import CancelIcon from "@mui/icons-material/Cancel";
import { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  useAssignUserRoleMutation,
  useGetUserRolesQuery,
  useUnassignUserRoleMutation,
} from "../app/services/userRole";
import "./common/Common.css";
import ServerGrid, { ServerGridProps } from "./common/ServerGrid";
import { useGetRolesQuery } from "../app/services/roles";
import { getApiErrorMessage } from "../util/getApiErrorMessage";

const UserRoles: FC = () => {
  const { userId } = useParams();
  const { userName, dmsDealerId } = useLocation().state as any;
  const [rows, setRows] = useState<CreateRoleResponse[]>([]);
  const [roles, setRoles] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState<UnassignUserRole>({
    userId: userId !== undefined ? userId : "",
    roleId: [],
  });

  const userRoleColumns: GridColDef[] = [
    { field: "id", headerName: "Id" },
    {
      field: "name",
      headerName: "Role Name",
      filterable: true,
      flex: 1,
    },
    {
      field: "dmsDealerId",
      headerName: "Dms Dealer Id",
      filterable: true,
      flex: 1,
    },
    {
      field: "Actions",
      headerName: "Action",
      type: "actions",
      width: 150,
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Unassign Role"
          title="Unassign Role"
          onClick={() => handleUnassignUserRoleDialogOpen(params.row.id)}
          disabled={assignUserRoleIsLoading || unassignUserRoleIsLoading}
        />,
      ],
    },
  ];

  /*
   * API calls
   * */

  const {
    data: userRoles,
    isFetching: userRolesIsFetching,
    isLoading: userRolesIsLoading,
    error: userRolesError,
    isError: userRolesIsError,
    isSuccess: userRolesIsSuccess,
  } = useGetUserRolesQuery(userId !== undefined ? userId : "");

  const {
    data: apiRoles,
    isFetching: rolesIsFetching,
    isLoading: rolesIsLoading,
    error: rolesError,
    isError: rolesIsError,
    isSuccess: rolesIsSuccess,
  } = useGetRolesQuery({
    dmsDealerId: dmsDealerId as number | undefined,
  });

  const [
    doAssignUserRole,
    {
      isLoading: assignUserRoleIsLoading,
      isError: assignUserRoleIsError,
      error: assignUserRoleError,
      isSuccess: assignUserRoleIsSuccess,
    },
  ] = useAssignUserRoleMutation();

  const [
    doUnassignUserRole,
    {
      isLoading: unassignUserRoleIsLoading,
      isError: unassignUserRoleIsError,
      error: unassignUserRoleError,
      isSuccess: unassignUserRoleIsSuccess,
    },
  ] = useUnassignUserRoleMutation();

  /*
   * Effects
   * */

  useEffect(() => {
    if (userRolesIsSuccess) setRows(userRoles);
  }, [userRoles, userRolesIsSuccess]);

  useEffect(() => {
    if (rolesIsSuccess)
      setRoles(
        apiRoles?.data?.map((role: any) => ({
          id: role?.id,
          name: role?.name,
        }))
      );
  }, [apiRoles, rolesIsSuccess]);

  useEffect(() => {
    if (selectedRole !== "")
      doAssignUserRole({
        userId: userId !== undefined ? [userId] : [""],
        roleId: roles?.find((role) => role.name === selectedRole)?.id ?? "",
      }).then(() => {
        setSelectedRole("");
        setIsSnackbarOpen(true);
        setSnackbarMessage("Assigned Role successfully");
      });
  }, [selectedRole]);

  useEffect(() => {
    if (userRolesIsError) {
      setSnackbarMessage(
        getApiErrorMessage({
          error: {
            ...userRolesError,
          },
        })
      );
      setIsSnackbarOpen(true);
    }
  }, [userRolesIsError]);

  useEffect(() => {
    if (rolesIsError) {
      setSnackbarMessage(
        getApiErrorMessage({
          error: {
            ...rolesError,
          },
        })
      );
      setIsSnackbarOpen(true);
    }
  }, [rolesIsError]);

  /*
   * Handlers
   * */

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const handleConfirm = async () => {
    setConfirmationDialogOpen(false);
    selectedUserRole.roleId.length > 0 &&
      (await doUnassignUserRole(selectedUserRole));
    setIsSnackbarOpen(true);
    setSnackbarMessage("Unassigned Role successfully");
  };

  const handleUnassignUserRoleDialogOpen = (roleId: string) => {
    setSelectedUserRole((prev) => ({
      ...prev,
      roleId: [roleId],
    }));
    setConfirmationDialogOpen(true);
  };

  const serverGridProps: ServerGridProps = {
    rows,
    columns: userRoleColumns,
    rowCount: userRoles?.length,
    isLoading: userRolesIsFetching || userRolesIsLoading,
    keepNonExistentRowsSelected: true,
    hiddenColumns: ["id"],
    usageType: "Role"
  };

  return (
    <Box id="base-padding">
      {snackbarMessage !== "" && (
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            severity={
              assignUserRoleIsSuccess || unassignUserRoleIsSuccess
                ? "success"
                : "error"
            }
            onClose={handleSnackbarClose}
          >
            {snackbarMessage ||
              JSON.stringify(
                assignUserRoleIsError
                  ? assignUserRoleError
                  : unassignUserRoleError,
                null,
                2
              ) ||
              "Error performing operation"}
          </Alert>
        </Snackbar>
      )}

      <Box mx={3}>
        <Box display={"flex"}>
          <Typography variant="h5" gutterBottom>
            Role(s) of
          </Typography>
          <Box width={8} />
          <Typography variant="h5" gutterBottom color={"#3f51b5"}>
            {userName}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            "& .MuiInputBase-root": {
              boxShadow: "0px 0px 10px 0px rgba(0.5,0,0,0.1)",
              borderRadius: 7,
              bgcolor: "white",
            },
          }}
        >
          <TextField
            select
            label="Assign Role"
            fullWidth
            margin="normal"
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
            disabled={assignUserRoleIsLoading || unassignUserRoleIsLoading}
          >
            {(rolesIsLoading || rolesIsFetching) && (
              <MenuItem value={0}>Loading...</MenuItem>
            )}
            {roles?.map((role) => (
              <MenuItem
                key={role?.id}
                value={role?.name}
                disabled={
                  userRoles?.find(
                    (userRole) => userRole?.name === role?.name
                  ) !== undefined
                }
              >
                {role?.name}
              </MenuItem>
            ))}
          </TextField>
          {(assignUserRoleIsLoading || unassignUserRoleIsLoading) && (
            <LinearProgress />
          )}
        </Box>

        <ServerGrid {...serverGridProps} />
      </Box>

      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      >
        <Box>
          <DialogTitle>
            Are you sure you want to unassign this role?
          </DialogTitle>
          <DialogActions>
            <Button variant="outlined" onClick={handleConfirm}>
              Yes
            </Button>
            <Button
              variant="outlined"
              onClick={() => setConfirmationDialogOpen(false)}
            >
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default UserRoles;

import { FC, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useGetDealerId } from "../../../app/features/salestaxSlice";
import { useGetSalesTaxTransactionLogMutation } from "../../../app/services/salestax";
import { formatDateToLocalTime } from "../../../util/formatDate";

const TransactionLog: FC = () => {
  const DealerId = useGetDealerId();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  /*
   * API calls
   * */

  //* Get SalesTax Transaction Log API
  const [
    getSalesTaxTransactionLog,
    {
      data: transactionLog,
      error: transactionLogError,
      isError: transactionLogIsError,
      isLoading: transactionLogIsLoading,
      isSuccess: transactionLogIsSuccess,
    },
  ] = useGetSalesTaxTransactionLogMutation();

  /*
   * Effects
   * */

  // Fetch SalesTax TransactionLog
  useEffect(() => {
    const getTransactionLog = async () => {
      const body = {
        query: "GetSalesTaxTransactionLog",
        queryBody: {
          DealerId,
          PageNumber: page,
          PageSize: rowsPerPage,
        },
      };

      await getSalesTaxTransactionLog(body);
    };

    getTransactionLog();
  }, [page, rowsPerPage]);

  /*
   * Handlers
   * */

  const handlePageChange = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Typography
        sx={{
          fontWeight: "bold",
          marginBottom: "15px",
        }}
      >
        Transaction Log
      </Typography>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="simple table">
          <TableHead sx={{ background: "#e5e5e5" }}>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Sr. No.</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Request</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Response</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Environment</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Log Date</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {transactionLog?.length > 0 &&
              transactionLog.map((row: any, index: number) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>

                  <TableCell>
                    <Tooltip arrow title={row.Request}>
                      <span>
                        {row.Request.length > 200
                          ? row.Request.substring(0, 50) + "..."
                          : row.Request}
                      </span>
                    </Tooltip>
                  </TableCell>

                  <TableCell>
                    <Tooltip arrow title={row.Response}>
                      <span>
                        {row.Response.length > 200
                          ? row.Response.substring(0, 50) + "..."
                          : row.Response}
                      </span>
                    </Tooltip>
                  </TableCell>

                  <TableCell>{row.Environment}</TableCell>

                  <TableCell>{formatDateToLocalTime(row.LogDate)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[15, 25, 50, 100]}
        component="div"
        count={transactionLog?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  );
};

export default TransactionLog;

import { GridDensity } from "@mui/x-data-grid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../hooks";

interface CommonState {
  density: GridDensity;
}

const initialState: CommonState = {
  density: "compact",
};

const apiSlice = createSlice({
  name: "api",
  initialState: initialState,
  reducers: {
    setDensity: (state, action: PayloadAction<GridDensity>) => {
      state.density = action.payload;
    },
  },
});

export const { setDensity } = apiSlice.actions;

export default apiSlice.reducer;

export const useDensity = () => useAppSelector((state) => state.api.density);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialPaginationModel } from "../../constants/variables";
import { useAppSelector } from "../hooks";

const featureSlice = createSlice({
  name: "feature",
  initialState: initialPaginationModel,
  reducers: {
    setFeaturePaginationModel: (
      state,
      action: PayloadAction<PaginationModel>
    ) => {
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
    },
  },
});

export const { setFeaturePaginationModel } = featureSlice.actions;

export default featureSlice.reducer;

export const useFeaturePaginationModel = () =>
  useAppSelector((state) => state.feature);

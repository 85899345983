import { combineReducers } from "redux";

import { idmsApi } from "./services/api";
import { everestApi } from "./services/customAuthApi";

import authReducer from "./features/authSlice";
import userReducer from "./features/userSlice";
import roleReducer from "./features/roleSlice";
import productReducer from "./features/productSlice";
import manageProductReducer from "./features/manageProductSlice";
import featureReducer from "./features/featureSlice";
import apiReducer from "./features/apiSlice";
import salesTaxReducer from "./features/salestaxSlice";

export default combineReducers({
  [idmsApi.reducerPath]: idmsApi.reducer,
  [everestApi.reducerPath]: everestApi.reducer,

  auth: authReducer,
  user: userReducer,
  role: roleReducer,
  product: productReducer,
  manageProduct: manageProductReducer,
  feature: featureReducer,
  api: apiReducer,
  salesTax: salesTaxReducer,
});

import { idmsApi } from "./api";
import { API_USERPRODUCT_ENDPOINT } from "../../constants/endpoints";
import { convertObjectToQueryParams } from "../../util/convertObjectToQueryParams";

const userProductApi = idmsApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserProducts: builder.query<Product[], string>({
      query: (userId) => `${API_USERPRODUCT_ENDPOINT}/${userId}/product`,
      providesTags: ["UserProduct"],
    }),

    getProductUsers: builder.query<any, any>({
      query: ({ productId, page, pageSize, dealerId, filterType, searchText }) =>`${API_USERPRODUCT_ENDPOINT}/${productId}?${convertObjectToQueryParams(
        {
          page,
          pageSize,
          dealerId,
          filterType,
          searchText
        }
      )}`,
      providesTags: ["UserProduct"],
    }),
    assignUserProduct: builder.mutation<any, CreateUserProduct>({
      query: (body) => ({
        url: `${API_USERPRODUCT_ENDPOINT}/products`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["UserProduct"],
    }),

    unassignUserProduct: builder.mutation<any, CreateUserProduct>({
      query: (body) => ({
        url: API_USERPRODUCT_ENDPOINT,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["UserProduct"],
    }),
  }),
});

export const {
  useGetUserProductsQuery,
  useGetProductUsersQuery,
  useAssignUserProductMutation,
  useUnassignUserProductMutation,
} = userProductApi;

import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Button,
  Box,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TextField,
  Typography,
  IconButton,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
  Tooltip,
  Drawer,
  Toolbar,
  Container,
  Dialog,
  DialogTitle,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { useFormik } from "formik";
import { createRoleValidationSchema } from "../util/validateSchema";
import { getApiErrorMessage } from "../util/getApiErrorMessage";
import {
  useAssignRolePermissionsMutation,
  useCreateRoleMutation,
  useGetRolePermissionsQuery,
  useGetRolesQuery,
} from "../app/services/roles";
import CloseIcon from "@mui/icons-material/Close";
import { useGetProductFeaturesQuery } from "../app/services/product";
import AddIcon from "@mui/icons-material/Add";
import { useFeaturePaginationModel } from "../app/features/featureSlice";
import { useGetDealersByProductQuery } from "../app/services/dealer";
import { StyledTableHead } from "../constants/variables";
import DeleteIcon from "@mui/icons-material/Delete";

interface FeatureSelections {
  [key: string]: boolean;
}
interface Permissions {
  permissionId?: string;
  roleId: string;
  productId: string;
  productFeatureId: string;
  permission: boolean;
}
interface RolePermissions {
  roleAccessPermissions: Permissions[];
}
const ManageRole: React.FC<ProductFormProps> = ({ selectedProductId }) => {
  const [rolePermissions, setProductRolePermissions] =
    useState<RolePermissions>({ roleAccessPermissions: [] });
  const [openCreateRoleDialog, setOpenCreateRoleDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState<any>("");
  const [selectedDmsDealerId, setSelectedDmsDealerId] = useState<any>("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const paginationModel = useFeaturePaginationModel();
  const [featureSelections, setFeatureSelections] = useState<FeatureSelections>(
    {}
  );
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [roleExistingPermissions, setRoleExistingPermissions] = useState<any>(
    []
  );
  const [unassignDialogOpen, setUnAssignnDialogOpen] = useState(false);
  const [unassignedRoleFeature, setUnAssignedRoleFeature] = useState<any>();

  const [filter, setFilter] = useState<GetRolesSearchCriteria>({
    id: undefined,
    productId: selectedProductId,
    byName: undefined,
    dmsDealerId: undefined,
  });

  const [roleDto, setPermissionDto] = useState<RolePermissionsDto>({
    roleId: "",
    productId: "",
    includeFeatures: true,
  });

  /*
   * Formik
   * */

  const createRoleFormik = useFormik({
    initialValues: {
      name: "",
      //productId: "",
      dmsDealerId: 0,
    } as CreateRole,
    validationSchema: createRoleValidationSchema,

    onSubmit: async (values, { resetForm }) => {
      values.productId = selectedProductId;
      values.name = values.name.trim();
      let payload: any = await doCreateRole(values);
      if ((payload as any)?.error?.data) {
        setSnackbarMessage(getApiErrorMessage(payload));
        setSnackbarSeverity("error");
        setIsSnackbarOpen(true);
        return;
      }

      setSnackbarMessage(
        getApiErrorMessage(payload, "Role created successfully")
      );
      setSnackbarSeverity("success");
      setIsSnackbarOpen(true);
      setOpenCreateRoleDialog(false);
      refetchRoles();
      //resetForm();
      createRoleFormik.setFieldValue("name", "");
      if (selectedDealer) {
        setTimeout(() => {
          const newSelectedRole = payload?.data?.id;
          setSelectedRole(newSelectedRole);
          setSelectedRoleName(payload?.data?.name);
          setPermissionDto((prev) => ({
            ...prev,
            roleId: newSelectedRole,
            productId: selectedProductId,
            includeFeatures: true,
          }));
        }, 500);
      }
    },
  });

  /*
   * API calls
   * */
  const [
    doCreateRole,
    {
      error: createRoleError,
      isError: createRoleIsError,
      isLoading: createRoleIsLoading,
      isSuccess: createRoleIsSuccess,
    },
  ] = useCreateRoleMutation();

  const [
    doAssignRolePermissions,
    {
      error: createPermissionError,
      isError: createPermissionIsError,
      isLoading: createPermissionIsLoading,
      isSuccess: createPermissionIsSuccess,
    },
  ] = useAssignRolePermissionsMutation();

  const {
    data: features = [],
    isFetching: featuresIsFetching,
    isSuccess: featuresIsSuccess,
    isError: featuresIsError,
    error: featuresError,
    refetch: refetchFeatures,
  } = useGetProductFeaturesQuery(
    {
      productId: selectedProductId,
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
    },
    { skip: !selectedProductId }
  );

  const {
    data: roles = [],
    isError: rolesIsError,
    error: rolesError,
    refetch: refetchRoles,
  } = useGetRolesQuery({
    ...filter,
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
  });

  const {
    data: rolePermissionsData = [],
    isError: roleIsError,
    error: roleError,
    refetch: refetchRolePermissions,
  } = useGetRolePermissionsQuery(roleDto, { skip: !roleDto.roleId });

  const {
    data: productDealers = [],
    isFetching: productDealersIsFetching,
    isSuccess: productDealersIsSuccess,
    isError: productDealersIsError,
    error: productDealersError,
    refetch: refetchProductDealers,
  } = useGetDealersByProductQuery(selectedProductId);
  /*
   * Effects
   * */
  useEffect(() => {
    if (roleDto) {
      if (roleDto.roleId) {
        refetchRolePermissions()
          .then((response) => {
            setRoleExistingPermissions(response.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  }, [roleDto]);

  useEffect(() => {
    if (featuresIsSuccess && features.data) {
      const initialSelections = features.data.reduce(
        (acc: any, feature: { id: any }) => ({
          ...acc,
          [feature.id]: false,
        }),
        {}
      );
      setFeatureSelections(initialSelections);
    }
  }, [features, featuresIsSuccess]);

  // Fetch product features when the selected product changes
  useEffect(() => {
    if (selectedProductId) {
      setFeatureSelections({});
      setSelectedRole("");
      setSelectedRoleName("");
      const newFilter = {
        ...filter,
        productId: selectedProductId,
        dmsDealerId: selectedDmsDealerId,
      };
      setFilter(newFilter);
      setRoleExistingPermissions([]);
      refetchRoles();
    }
  }, [selectedDealer]);

  useEffect(() => {
    setRoleExistingPermissions(rolePermissionsData);
  }, [rolePermissionsData]);

  /*
   * Functions
   * */
  const handleCheckboxChange = (featureId: string) => {
    if (!validateRequest()) return;

    const currentFeatureState = featureSelections[featureId];
    setFeatureSelections((prev) => ({
      ...prev,
      [featureId]: !currentFeatureState,
    }));

    // Prepare the permission object
    const permission = {
      roleId: selectedRole,
      productId: selectedProductId,
      productFeatureId: featureId,
      permission: !currentFeatureState,
    };

    setProductRolePermissions((prev) => {
      // Check if the permission already exists
      const existingIndex = prev.roleAccessPermissions.findIndex(
        (p) =>
          p.roleId === permission.roleId &&
          p.productId === permission.productId &&
          p.productFeatureId === permission.productFeatureId
      );

      let newPermissions = [...prev.roleAccessPermissions];

      if (currentFeatureState && existingIndex !== -1) {
        // If unchecking, remove the permission from the array
        newPermissions.splice(existingIndex, 1);
      } else if (!currentFeatureState && existingIndex === -1) {
        // If checking and permission does not exist, add it to the array
        newPermissions.push(permission);
      }

      return { roleAccessPermissions: newPermissions };
    });
  };
  const handleCreateRoleDialogClose = () => setOpenCreateRoleDialog(false);
  const handleSnackbarClose = () => setIsSnackbarOpen(false);

  function validateRequest() {
    if (!selectedDealer) {
      setSnackbarMessage("Please select a Dealer");
      setSnackbarSeverity("error");
      setIsSnackbarOpen(true);
      return false;
    }

    if (!selectedRole) {
      setSnackbarMessage("Please select a Role");
      setSnackbarSeverity("error");
      setIsSnackbarOpen(true);
      return false;
    }
    return true;
  }
  const handleSave = async () => {
    if (!validateRequest()) return;

    if (
      features.data.length === roleExistingPermissions.length &&
      roleExistingPermissions.every(
        (item: { permission: boolean }) => item.permission === true
      )
    ) {
      setSnackbarMessage(
        `All features are already assigned to "${selectedRoleName}" role`
      );
      setSnackbarSeverity("error");
      setIsSnackbarOpen(true);
      return;
    }

    if (rolePermissions.roleAccessPermissions.length === 0) {
      setSnackbarMessage("Please select one or more Feature(s)");
      setSnackbarSeverity("error");
      setIsSnackbarOpen(true);
      return;
    }

    try {
      const payload = await doAssignRolePermissions(rolePermissions).unwrap();
      setSnackbarMessage(
        getApiErrorMessage(payload, "Feature(s) updated successfully")
      );
      setSnackbarSeverity("success");
      setProductRolePermissions({ roleAccessPermissions: [] });
      setFeatureSelections({});
      refetchRolePermissions();
      setIsSnackbarOpen(true);
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage(
        getApiErrorMessage(error, "Failed to update Feature(s)")
      );
      setIsSnackbarOpen(true);
    }
  };

  const handleUnassign = async () => {
    try {
      if (unassignedRoleFeature) {
        const permId = roleExistingPermissions.find(
          (existingFeature: {
            productFeatureId: any;
            productFeatureName: any;
            permissionId: any;
          }) =>
            existingFeature.productFeatureId === unassignedRoleFeature.id &&
            existingFeature.productFeatureName ===
              unassignedRoleFeature.productFeatureName
        )?.permissionId;

        const permission: Permissions = {
          permissionId: permId,
          roleId: selectedRole,
          productId: selectedProductId,
          productFeatureId: unassignedRoleFeature.id,
          permission: false,
        };

        setProductRolePermissions({ roleAccessPermissions: [permission] });
        const payload = await doAssignRolePermissions({
          roleAccessPermissions: [permission],
        }).unwrap();
        setSnackbarMessage(
          getApiErrorMessage(payload, "Feature Unassigned successfully")
        );
        setSnackbarSeverity("success");
        setUnAssignnDialogOpen(false);
        setProductRolePermissions({ roleAccessPermissions: [] });
        refetchRolePermissions();
        setIsSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage(getApiErrorMessage(error, "Failed to update feature"));
      setIsSnackbarOpen(true);
    }
  };

  const handleCreateRole = () => {
    setOpenCreateRoleDialog(true);
  };

  return (
    <Box
      sx={{
        marginLeft: { xs: 2, sm: 4, md: 10, lg: 20 },
        marginRight: { xs: 2, sm: 4, md: 10, lg: 20 },
        marginTop: 2,
      }}
    >
      {snackbarMessage !== "" && (
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}

      <Box display={"flex"} justifyContent={"flex-end"}>
        <Tooltip title="Add New Role">
          <Button
            sx={{
              paddingRight: "15px",
              color: "white",
              margin: "10px",
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
            }}
            onClick={handleCreateRole}
          >
            <AddIcon />
            <Typography variant="button">Role</Typography>
          </Button>
        </Tooltip>
      </Box>
      <Box
        component="form"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Autocomplete
          fullWidth
          options={Array.isArray(productDealers) ? productDealers : []}
          noOptionsText="No Dealer Available"
          disabled={!productDealers || productDealers.length === 0}
          getOptionLabel={(dealer) => `${dealer.name ?? ""} (${dealer.id})`}
          value={
            productDealers && productDealers.length > 0
              ? productDealers?.find(
                  (dealer: { id: any }) => dealer.id === selectedDealer
                ) || null
              : null
          }
          onChange={(event, newValue) => {
            setSelectedDmsDealerId(newValue?.dmsDealerId || "");
            setSelectedDealer(newValue?.id || "");
            createRoleFormik.setFieldValue(
              "dmsDealerId",
              newValue?.dmsDealerId || ""
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Dealer"
              size="small"
              required
              margin="dense"
              sx={{
                backgroundColor: "background.paper",
                boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "background.default",
                },
                "& .Mui-focused": {
                  borderColor: "primary.main",
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
                },
              }}
            />
          )}
          renderOption={(props, dealer) => (
            <li {...props} key={dealer.id}>
              {`${dealer.name ?? ""} (${dealer.id})`}
            </li>
          )}
          ListboxProps={{
            sx: {
              maxHeight: 300,
              overflowY: "auto",
              borderRadius: "4px",
              boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            },
          }}
        />
        <TextField
          name="roleId"
          select
          label="Select Role"
          fullWidth
          required
          size="small"
          margin="dense"
          value={selectedRole}
          onChange={(e) => {
            const newSelectedRole = e.target.value;
            const selectedRoleObj = roles.data.find(
              (role: { id: string }) => role.id === newSelectedRole
            );
            setSelectedRole(newSelectedRole);
            setSelectedRoleName(selectedRoleObj ? selectedRoleObj?.name : "");
            setPermissionDto((prev) => ({
              ...prev,
              roleId: newSelectedRole,
              productId: selectedProductId,
              includeFeatures: true,
            }));
          }}
          sx={{
            backgroundColor: "background.paper",
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
            "&:hover": {
              backgroundColor: "background.default",
            },
            "& .Mui-focused": {
              borderColor: "primary.main",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          {selectedDealer && roles.data.length > 0 ? (
            (roles?.data as Role[])?.map((role, index) => (
              <MenuItem key={index} value={role.id}>
                {role.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" disabled>
              No Role Available
            </MenuItem>
          )}
        </TextField>
      </Box>

      {/*---------- Create Role Dialogs ----------*/}
      <Drawer
        anchor="right"
        id="create-role-drawer"
        open={openCreateRoleDialog}
        onClose={handleCreateRoleDialogClose}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Box style={{ width: "525px" }}>
          <Toolbar variant="dense">
            <Box flexGrow={1}>
              <Typography variant="h5">Add New Role</Typography>
            </Box>
            <IconButton onClick={handleCreateRoleDialogClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Container>
            <Box
              component="form"
              autoComplete="off"
              py={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                id="name"
                label="Role Name"
                fullWidth
                autoFocus
                required
                size="small"
                margin="dense"
                value={createRoleFormik.values.name}
                error={Boolean(createRoleFormik.errors.name)}
                helperText={createRoleFormik.errors.name}
                onChange={createRoleFormik.handleChange}
              />
              <Autocomplete
                options={Array.isArray(productDealers) ? productDealers : []}
                disabled={!productDealers || productDealers.length === 0}
                getOptionLabel={(option) =>
                  `${option.name ?? ""} (${option.id})`
                }
                value={
                  productDealers && productDealers.length > 0
                    ? productDealers.find(
                        (dealer: { dmsDealerId: number; id: number }) =>
                          dealer.dmsDealerId ===
                            createRoleFormik.values.dmsDealerId &&
                          dealer?.id === selectedDealer
                      )
                    : null
                }
                onChange={(event, newValue) => {
                  createRoleFormik.setFieldValue(
                    "dmsDealerId",
                    newValue?.id || ""
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Dealer"
                    fullWidth
                    required
                    size="small"
                    margin="dense"
                    error={Boolean(createRoleFormik.errors.dmsDealerId)}
                    helperText={createRoleFormik.errors.dmsDealerId}
                    sx={{
                      backgroundColor: "white",
                      "& .MuiAutocomplete-inputRoot": {
                        padding: "10px",
                      },
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText="No Dealer Available"
                sx={{
                  "& .MuiAutocomplete-option": {
                    borderBottom: "1px solid #ddd",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                    "&:hover": {
                      backgroundColor: "#f4f4f4",
                    },
                  },
                  "& .MuiAutocomplete-paper": {
                    maxHeight: 300,
                    maxWidth: 200,
                    overflowY: "auto",
                    borderRadius: "4px",
                    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                  },
                }}
              />
            </Box>
          </Container>

          <Box display={"flex"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              onClick={() => createRoleFormik.handleSubmit()}
              disabled={createRoleIsLoading}
              sx={{
                margin: "-10px 18px 0 0",
              }}
            >
              Save
              {createRoleIsLoading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                    color: "secondary",
                  }}
                  size={24}
                />
              )}
            </Button>
          </Box>
        </Box>
      </Drawer>
      <Box
        component="form"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          mt: 2,
        }}
      >
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "480px", display: "flex", flexDirection: "column" }}
        >
          {featuresIsFetching ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={3}
            >
              <CircularProgress />
            </Box>
          ) : features.data && features.data.length > 0 ? (
            <Table size="small" stickyHeader>
              <StyledTableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Feature</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {features.data.map((feature: any) => (
                  <TableRow key={feature.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          featureSelections[feature.id] ||
                          (roleExistingPermissions &&
                            roleExistingPermissions.find(
                              (existingFeature: {
                                productFeatureId: any;
                                productFeatureName: any;
                              }) =>
                                existingFeature.productFeatureId ===
                                  feature.id &&
                                existingFeature.productFeatureName ===
                                  feature.productFeatureName
                            )?.permission) ||
                          false
                        }
                        disabled={
                          roleExistingPermissions &&
                          roleExistingPermissions.find(
                            (existingFeature: {
                              productFeatureId: any;
                              productFeatureName: any;
                            }) =>
                              existingFeature.productFeatureId === feature.id &&
                              existingFeature.productFeatureName ===
                                feature.productFeatureName
                          )?.permission
                        }
                        onChange={() => handleCheckboxChange(feature.id)}
                        name={feature.productFeatureName}
                      />
                    </TableCell>
                    <TableCell>
                      {feature.productFeatureName + " - " + feature.description}
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{
                          color: "#007bff",
                          borderColor: "#007bff",
                          textTransform: "none",
                          "&:hover": {
                            borderColor: "#0056b3",
                            backgroundColor: "rgba(0, 123, 255, 0.1)",
                          },
                        }}
                        disabled={
                          !(
                            roleExistingPermissions &&
                            roleExistingPermissions.find(
                              (existingFeature: {
                                productFeatureId: any;
                                productFeatureName: any;
                              }) =>
                                existingFeature.productFeatureId ===
                                  feature.id &&
                                existingFeature.productFeatureName ===
                                  feature.productFeatureName
                            )?.permission
                          )
                        }
                        onClick={() => {
                          setUnAssignedRoleFeature(feature);
                          setUnAssignnDialogOpen(true);
                        }}
                        startIcon={<DeleteIcon />}
                      >
                        Unassign
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={3}
            >
              <Typography>No features available for this product.</Typography>
            </Box>
          )}
        </TableContainer>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        {features.data ? (
          <Button
            variant="contained"
            onClick={handleSave}
            size="small"
            disabled={createPermissionIsLoading}
          >
            Assign Feature(s)
            {createPermissionIsLoading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                  color: "secondary",
                }}
                size={24}
              />
            )}
          </Button>
        ) : null}
      </Box>
      <Dialog
        open={unassignDialogOpen}
        onClose={() => {
          setUnAssignedRoleFeature(undefined);
          setUnAssignnDialogOpen(false);
        }}
      >
        <Box>
          <DialogTitle>
            "{unassignedRoleFeature?.productFeatureName}" will be removed from
            the "{selectedRoleName}" role. Are you sure you want to continue?
          </DialogTitle>

          <DialogActions>
            <Button variant="outlined" onClick={handleUnassign}>
              Yes
              {createPermissionIsLoading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                    color: "secondary",
                  }}
                  size={24}
                />
              )}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setUnAssignnDialogOpen(false);
                setUnAssignedRoleFeature(undefined);
              }}
            >
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ManageRole;
